const css = `.sri-components-indicatorTaskData-indicator-task {
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  background: white;
}
.sri-components-indicatorTaskData-indicator-task .student-component-requirementInfo-bar {
  position: absolute;
  width: 8px;
  left: 0px;
  top: 0%;
  bottom: 0%;
  border-radius: 4px 0px 0px 4px;
}
.sri-components-indicatorTaskData-indicator-task .student-component-requirementInfo-bar.met {
  background: #3e8d21;
}
.sri-components-indicatorTaskData-indicator-task .student-component-requirementInfo-bar.inprogress {
  background: #0075db;
}
.sri-components-indicatorTaskData-indicator-task .student-component-requirementInfo-bar.notmet {
  background: #677274;
}
.sri-components-indicatorTaskData-indicator-task .student-component-requirementInfo-bar.nodata {
  background: #c4c9ca;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 {
  border: 0px;
  padding-bottom: 60px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .student-component-requirementInfo-bar {
  display: none;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children {
  flex-direction: row;
  display: flex;
  padding: 0px 38px;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children > .sri-components-indicatorTaskData-children-col {
  width: calc(50% - 10px);
}
@media screen and (max-width: 950px) {
  .sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children > .sri-components-indicatorTaskData-children-col {
    width: 100%;
  }
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children > .sri-components-indicatorTaskData-indicator-task {
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #cacaca;
  flex: inherit;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-description {
  padding: 25px 38px;
  display: block;
}
.sri-components-indicatorTaskData-indicator-task.depth-0 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-category-name {
  font-size: 20px;
  font-weight: 500;
  display: block;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 {
  background: #f6f6fa;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children {
  display: flex;
  padding: 0px 20px;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-category-name {
  padding: 20px 25px 10px 25px;
  font-weight: 500;
  font-size: 16px;
  display: block;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > .student-component-requirementInfo-bar {
  display: none;
}
.sri-components-indicatorTaskData-indicator-task.depth-1 > .sri-components-indicatorTaskData-description {
  display: block;
  padding: 5px 25px 5px 25px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-description {
  padding: 5px 25px 5px 46px;
  display: none;
  font-size: 16px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-category .sri-components-indicatorTaskData-children {
  display: none;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 10px 0px 46px;
}
.sri-components-indicatorTaskData-indicator-task input[type=checkbox] {
  display: none;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-children {
  display: flex;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-category > .sri-components-indicatorTaskData-category-name {
  display: block;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-description {
  display: block;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-description {
  display: block;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-label .accordion__arrow {
  transform: rotateZ(0deg);
  height: 24px;
}
.sri-components-indicatorTaskData-indicator-task input:checked ~ .sri-components-indicatorTaskData-label .sri-components-indicatorTaskData-header {
  padding-bottom: 8px;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-category-name {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  padding: 20px 0px 10px 46px;
  display: none;
}
.sri-components-indicatorTaskData-indicator-task .sri-components-indicatorTaskData-description + .sri-components-indicatorTaskData-category .sri-components-indicatorTaskData-category-name {
  padding-top: 0px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50L0NvbXBvbmVudHMvU3R1ZGVudEluZGljYXRvcnMiLCJzb3VyY2VzIjpbIlN0dWRlbnRJbmRpY2F0b3JzLnNjc3MiLCIuLi8uLi9kZWZpbml0aW9ucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQTZGQTs7QUE1RkE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRSxZQ2ZROztBRGtCWjtFQUNFO0VBQ0E7O0FBQ0E7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFOztBQUVGO0VBQ0U7SUFDRTs7O0FBR0o7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFJSjtFQUNFO0VBQ0E7O0FBR0E7RUFDRTtFQUNBO0VBQ0E7O0FBS047RUFDRTs7QUFFQTtFQUNFO0VBQ0E7O0FBSUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFJSjtFQUNFOztBQUdGO0VBQ0U7RUFDQTs7QUFJSjtFQUNFO0VBQ0E7RUFDQTs7QUFNQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFJSjtFQUNFOztBQUdGO0VBR0U7O0FBR0Y7RUFHRTs7QUFHRjtFQUNFOztBQUVGO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTtFQUNBO0VBQ0EsT0NySUk7RURzSUo7RUFDQTs7QUFHQTtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};

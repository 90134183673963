import './toastr.scss';
import React, { useEffect, useState } from 'react';
import { TOASTER_DEFAULT_TIMEOUT } from '../../constants';
import { Icon } from '../Icon';
type Type = 'success' | 'error' | 'warning' | 'info';

export interface ToastrItem {
  type?: Type;
  text?: string;
  show?: boolean;
  isClose?: boolean;
}

export const Toastr = (props: React.PropsWithChildren<ToastrItem>): JSX.Element => {
  (Toastr as React.FC).displayName = 'Toastr';
  const { type, text, isClose, children } = props;

  const [showToastr, setShowToastr] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(
      () => setShowToastr(!showToastr),
      TOASTER_DEFAULT_TIMEOUT
    ) as unknown as NodeJS.Timeout;
    return () => clearTimeout(timer);
  }, []);

  const closeToastrMessage = () => {
    setShowToastr(false);
  };

  return (
    <>
      {showToastr && (
        <div className={`toastr ${type}`}>
          {type == 'success' && <div className="toastr-border-left"></div>}
          <div className="toastr-icon">{type && <Icon name={type} />}</div>
          <div className={`toastr-message ${type == 'success' ? type : ''}`}>
            {text ? <div dangerouslySetInnerHTML={{ __html: text }}></div> : children}
          </div>
          {isClose && (
            <div className="toastr-close" onClick={closeToastrMessage}>
              <Icon name="cross" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

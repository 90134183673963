const css = `.sri-main {
  display: block;
  font-family: "Open Sans", sans-serif;
  position: fixed;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.sri-banner {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 27px;
  padding: 15px 10px 10px 15px;
  border-bottom: 1px solid #aaaaaa;
}

.sri-app-name-info {
  display: flex;
}

.banner-dropdowns {
  display: flex;
}

.sri-containers-page-info {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  font-family: "Ubuntu";
  font-style: normal;
}
.sri-containers-page-info .sri-containers-page-display-left {
  width: 22%;
  margin-left: 56px;
  margin-top: 40px;
  margin-right: 47px;
  padding-bottom: 40px;
}
.sri-containers-page-info .sri-containers-page-display-left .sri-containers-page-display-name {
  font-weight: 400;
  font-size: 27px;
  line-height: 40px;
  color: #000000;
}
.sri-containers-page-info .sri-containers-page-break-div {
  margin-top: 46px;
  margin-right: 50px;
  width: 1px;
  height: auto;
  background: #c4c4c4;
}
.sri-containers-page-info .sri-containers-page-display-right {
  margin-top: 40px;
  width: 630px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  overflow-wrap: break-word;
}
.sri-containers-page-info .sri-containers-page-display-right .sri-containers-page-display-instr {
  display: block;
}
.sri-containers-page-info .sri-containers-page-display-right .sri-containers-page-display-school-message > ul {
  margin-bottom: 0px;
}
.sri-containers-page-info .sri-containers-page-display-right .sri-containers-page-display-school-message > p {
  margin-bottom: 0px;
  margin-top: 0.25rem;
}

.sri-containers-page-body {
  padding-top: 41px;
  padding-bottom: 50px;
  background-color: #e6e6f1;
  font-family: "Ubuntu";
  font-style: normal;
}
.sri-containers-page-body .sri-containers-page-header {
  height: 90px;
  background: #f6f6fa;
}
.sri-containers-page-body .sri-containers-page-header .sri-containers-page-header-title {
  width: 70%;
  height: 39px;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  padding-left: 32px;
  padding-top: 26px;
  color: #222222;
}
.sri-containers-page-body .sri-containers-page-header .sri-containers-page-header-status-info {
  display: flex;
  flex-direction: unset;
  align-items: center;
  padding: 0px;
  gap: 8.18px;
  margin-top: -22px;
  justify-content: end;
  margin-right: 34px;
}
.sri-containers-page-body .sri-containers-page-header .sri-containers-page-header-status-info .label-name {
  height: 35px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 22.9091px;
  line-height: 34px;
  color: #333333;
  mix-blend-mode: normal;
}
.sri-containers-page-body .sri-containers-page-header .sri-containers-page-header-status-info.nodata .label-name {
  font-weight: 400 !important;
  color: #6c7584 !important;
}
.sri-containers-page-body .sri-containers-page-header .sri-containers-page-header-met-info {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #5e6671;
  margin-right: 30px;
}
.sri-containers-page-body .sri-containers-page-all-data {
  padding-top: 25px;
  padding-bottom: 50px;
  background: #ffffff;
  overflow: auto;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-description {
  padding-left: 36px;
  width: 755px;
  height: 24px;
  left: 38px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data {
  padding-left: 36px;
  padding-right: 36px;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-category-data {
  background: #ffffff;
  clear: both;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-category-data .sri-containers-page-category-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-top: 40px;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-indicator-child {
  padding-bottom: 20px;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-indicator-child .sri-containers-page-break-bar {
  height: 1px;
  margin-right: -10px;
  margin-left: -20px;
  margin-bottom: 15px;
  background: #c4c4c4;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-indicator-child .sri-containers-page-indicator-shortDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.sri-containers-page-body .sri-containers-page-all-data .sri-containers-page-indicator-data .sri-containers-page-indicator-child .sri-containers-page-indicator-sub-category-name {
  padding-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50L2NvbnRhaW5lcnMvUGFnZSIsInNvdXJjZXMiOlsicGFnZS5zY3NzIiwiLi4vLi4vZGVmaW5pdGlvbnMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQSxPQ2xDRTs7QURzQ047RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBLFlDN0NHOztBRGdETDtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxPQ3BESTtFRHFESjs7QUFFQTtFQUNFOztBQUdGO0VBQ0U7O0FBRUY7RUFDRTtFQUNBOzs7QUFLTjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxPQ3pGTTs7QUQ0RlI7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DbkhXO0VEb0hYOztBQUdBO0VBQ0U7RUFDQTs7QUFLTjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFJSjtFQUNFO0VBQ0E7RUFDQSxZQ3pJUztFRDBJVDs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EsT0M5SUU7O0FEaUpKO0VBQ0U7RUFDQTs7QUFDQTtFQUNFLFlDM0pLO0VENEpMOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0EsT0M1SkY7RUQ2SkU7O0FBR0o7RUFDRTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUVBO0VBQ0EsWUMxS0g7O0FENktDO0VBQ0U7RUFDQTtFQUNBO0VBQ0EsT0NoTEU7O0FEbUxKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DeExGIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};

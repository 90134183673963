import { IS_STANDALONE_DEV } from './common';

export const BASE_API_URL = () => {
  return (window as unknown as any).SRI_STAFF_UI_CONTEXT?.API_URL;
};

export const GET_USER_DETAILS = '/user/info';
export const GET_NAV_HS_DETAILS = '/highschools';
export const GET_NAV_FEATURES = '/features';
export const GET_NAV_HS_FILTERED = '/highschools/info';

export const BASE_SRI_PATH = IS_STANDALONE_DEV ? '' : '/student-readiness-indicators';
export const GET_AGGREGATE_ENDPOINT = `${BASE_SRI_PATH}/aggregate`;
export const GET_DISTRICT_ENDPOINT = `${BASE_SRI_PATH}/districts`;
export const GET_SCHOOL_ENDPOINT = `${BASE_SRI_PATH}/schools`;
export const ACTIVATE_SCHOOL_ENDPOINT = `${BASE_SRI_PATH}/schools`;
export const GET_STATE_RULES_ENDPOINT = `${BASE_SRI_PATH}/state`;
export const GET_STUDENT_ENDPOINT = `${BASE_SRI_PATH}/student`;
export const UPDATE_STUDENT_INDICATORS_ENDPOINT = `${BASE_SRI_PATH}/schools`;
export const EXPORT_ALL_STUDENTS_ENDPOINT = `${BASE_SRI_PATH}/exportAllStudents`;

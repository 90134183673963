import React from 'react';
import './emptyDetails.scss';
import missingDocuments from '../../assets/missingDocuments.svg';

export interface Props {
  widget: boolean;
}

export const EmptyDetails = (props: Props): JSX.Element => {
  const { widget } = props;

  return (
    <React.Fragment>
      <div className={`sri-empty-details ${widget ? '' : 'sri-empty-details-page'}`}>
        <div
          className="sri-missing-documents"
          dangerouslySetInnerHTML={{ __html: missingDocuments }}
        />
        <div className="sri-empty-details-message">
          No Student Readiness Indicators information is available at this time. Check back later.
        </div>
      </div>
    </React.Fragment>
  );
};

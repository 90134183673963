import React, { useState, useEffect } from 'react';
import { getStateData, getStudentDetails } from '../../api';
import { Loader, LoaderItem } from '../../../staff/components/Loader';
import { StateRules, StateRulesField } from '../../../../interfaces/api';
import { APP_DISPLAY_NAME, STATIC_STUDENT_SCHOOL_MESSAGE, API_ERROR } from '../../constants';
import { Student, StudentResults } from '../../../../interfaces/api/students';
import { getStudentMessage } from '../../api/studentMessage';
import { StudentIndicators } from '../../Components/StudentIndicators/StudentIndicators';
export interface Props {
  appDisplayName?: string;
  apiUrl?: string;
  jwt?: string;
  state?: string;
}

export const RecursivePage = (props: Props): JSX.Element => {
  (RecursivePage as React.FC).displayName = 'RecursivePage';
  const { apiUrl, jwt } = props;

  if (apiUrl) {
    (window as unknown as any).SRI_STUDENT_UI_CONTEXT = { API_URL: apiUrl };
  }

  const [loading, setLoading] = useState(true);
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(false);
  const [studentMessage, setStudentMessage] = useState<string | undefined>('');
  const [stateRules, setStateRules] = useState<StateRules>();
  const [studentResults, setStudentResults] = useState<StudentResults>({});
  const [loader, setLoader] = useState<LoaderItem>({
    size: 'medium',
    loading: false,
  });
  const [studentLongDescriptionLeft, setStudentLongDescriptionLeft] = useState<string>('');
  const [studentLongDescriptionRight, setStudentLongDescriptionRight] = useState<string>('');

  const retrieveStudentMessage = async () => {
    const studentMessage = await getStudentMessage(jwt as unknown as string);
    if (studentMessage) {
      setStudentMessage(studentMessage.message);
    }
  };

  const getAndParseIndicators = async () => {
    const getData = await getStateData(jwt as unknown as string);
    const studentLongDescriptionLeft = getData.fields[0].studentLongDescription;
    const studentLongDescriptionRight = getData.fields[0]?.studentLongDescriptionRight;
    setStudentLongDescriptionLeft(studentLongDescriptionLeft);
    setStudentLongDescriptionRight(studentLongDescriptionRight || '');
    const filterScoreFields = (field: StateRulesField): StateRulesField => {
      return {
        ...field,
        fields: field.fields?.filter((r) => !r.isScore).map(filterScoreFields),
      };
    };
    setStateRules({
      ...getData,
      fields: getData.fields.map(filterScoreFields),
    });
  };

  const getAndParseIndicatorsStatus = async () => {
    const singleStudentDetails = await getStudentDetails(jwt as unknown as string);
    if (
      singleStudentDetails &&
      ((singleStudentDetails as { status: string }).status === API_ERROR ||
        !Object.keys((singleStudentDetails as Student)?.results).length)
    ) {
      setLoader({ loading: false });
    } else {
      setStudentResults((singleStudentDetails as Student)?.results);
    }
  };
  const showLoader = (loaderOps: LoaderItem) => {
    setLoader({
      ...loader,
      ...loaderOps,
    });
  };

  const handleTimeout = () => {
    setLoaderTimedOut(true);
  };

  useEffect(() => {
    /**
     * Make sure we only load data once
     */
    if (loading) {
      showLoader({ loading: true });
      Promise.all([
        retrieveStudentMessage(),
        getAndParseIndicatorsStatus(),
        getAndParseIndicators(),
      ]).then(() => setLoader({ loading: false }));
    }
    setLoading(false);
  }, [loading]);
  return (
    <>
      {loader.loading && (
        <div className="sri-containers-page-loader">
          <Loader
            size={loader.size}
            timeout={loader.timeout}
            onTimeout={handleTimeout}
            noToast={true}
          />
        </div>
      )}
      <div className="sri-containers-page-info">
        <div className="sri-containers-page-display-left">
          <span className="sri-containers-page-display-name">{APP_DISPLAY_NAME}</span>
          <br />
          <span
            data-test-id="sri-student-overview-description-left"
            dangerouslySetInnerHTML={{ __html: studentLongDescriptionLeft }}
          ></span>
        </div>
        <div className="sri-containers-page-break-div"></div>
        <div className="sri-containers-page-display-right">
          <span
            className="sri-containers-page-display-instr"
            data-test-id="sri-student-overview-description-right"
            dangerouslySetInnerHTML={{
              __html: studentLongDescriptionRight ? studentLongDescriptionRight : '',
            }}
          ></span>
          <br />
          <strong>Information From My School</strong>
          <br />
          <span
            className="sri-containers-page-display-school-message"
            data-test-id="sri-student-school-message"
            dangerouslySetInnerHTML={{
              __html: studentMessage ? studentMessage : '',
            }}
          ></span>
        </div>
      </div>
      <div className="sri-containers-page-body">
        {stateRules?.fields.map((rule) => (
          <StudentIndicators
            rules={rule}
            key={`student-indicator-${rule.id}`}
            studentResults={studentResults}
            topLevelRequirement={stateRules?.topLevelRequirementRule}
          ></StudentIndicators>
        ))}
      </div>
    </>
  );
};

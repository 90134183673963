/* eslint-disable react/prop-types */
import './App.scss';
import { Widget, Props as WidgetProps } from './containers/Widget';
import { APP_DISPLAY_NAME } from './constants';
import { getUserContextProps } from './utils';
import React from 'react';
import { FeatureFlagWrapper } from '../student/Components/FeatureFlagWrapper';
import { FeatureFlags } from '../student/api/featureflag';
import AppHelper from './Apphelper';

interface AppProps {
  widget?: boolean;
  myPlannerUrl?: string;
  apiUrl?: string;
  jwt?: string;
}

const widgetProps: WidgetProps = {
  appDisplayName: APP_DISPLAY_NAME,
  ...getUserContextProps(),
} as WidgetProps;

const App = (props: AppProps): JSX.Element => {
  (App as React.FC).displayName = 'SRI App Containers';

  const { widget, myPlannerUrl, apiUrl, jwt } = props;

  // TODO:: remove this once APIs, Backend Proxy, and Real Data is ready
  const isDev =
    location.port === '4214' ||
    location.port === '4204' ||
    location.hostname === 'sri-student.local.naviance.com' ||
    location.hostname.endsWith('.dev.cclr.naviance.com');
  if (isDev) {
    if (!window.SRI_STUDENT_UI_CONTEXT) {
      window.SRI_STUDENT_UI_CONTEXT = { props: {} } as Window['SRI_STUDENT_UI_CONTEXT'];
    }

    if (!widgetProps.state) {
      // State Overrides
      window.SRI_STUDENT_UI_CONTEXT.state =
        new URLSearchParams(location.search).get('state') || 'oh';
    }

    window.SRI_STUDENT_UI_CONTEXT.mockStudents =
      new URLSearchParams(location.search).get('mockStudents') || 'false';
  }

  if ((window as unknown as any).SRI_STUDENT_UI_CONTEXT === undefined) {
    (window as unknown as any).SRI_STUDENT_UI_CONTEXT = {};
  }
  if (!(window as unknown as any).SRI_STUDENT_UI_CONTEXT?.API_URL) {
    (window as unknown as any).SRI_STUDENT_UI_CONTEXT.API_URL = apiUrl || '';
  }

  return (
    <React.Fragment>
      {widget ? (
        <Widget myPlannerUrl={myPlannerUrl || ''} jwt={jwt} />
      ) : (
        <FeatureFlagWrapper
          flagName={FeatureFlags.loadPageRecursiveStudentIndicators}
          propName="loadPageRecursiveStudentIndicators"
        >
          <AppHelper jwt={jwt} />
        </FeatureFlagWrapper>
      )}
    </React.Fragment>
  );
};

export default App;

import {
  school_100001USPU,
  school_100002USPU,
  school_100003USPU,
  school_100004USPU,
  school_100005USPU,
  school_100006USPU,
} from './school';

export const district_100001DUS = {
  id: '100001DUS',
  name: 'Mock District 1',
  schools: [
    { ...school_100001USPU },
    { ...school_100002USPU },
    { ...school_100003USPU },
    { ...school_100004USPU },
  ],
};

export const district_100002DUS = {
  id: '100002DUS',
  name: 'Mock District 2',
  schools: [{ ...school_100003USPU }, { ...school_100004USPU }],
};

export const district_100003DUS = {
  id: '100003DUS',
  name: 'Mock District 3',
  schools: [{ ...school_100005USPU }, { ...school_100006USPU }],
};

export const district_100004DUS = {
  id: '100004DUS',
  name: 'Mock District 4',
  schools: [
    { ...school_100001USPU },
    { ...school_100002USPU },
    { ...school_100003USPU },
    { ...school_100004USPU },
    { ...school_100005USPU },
    { ...school_100006USPU },
  ],
};

import { ProvisionStatus } from '../../../interfaces/api';

export const school_100001USPU = {
  id: '100001USPU', // IDs here must match IDs in District API
  name: 'East Oak High School',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  hasImportedData: false,
  counselors: [
    {
      id: '100001',
      name: 'Juan Enders',
      firstName: 'Juan',
      lastName: 'Enders',
    },
    {
      id: '100002',
      name: 'Phillis Tsal',
      firstName: 'Phillis',
      lastName: 'Tsal',
    },
  ],
  classYears: [2023, 2024, 2025],
};

export const school_100002USPU = {
  id: '100002USPU', // IDs here must match IDs in District API
  name: 'West Oak High School',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  hasImportedData: false,
  counselors: [
    {
      id: '100004',
      name: 'Lily Lavender',
      firstName: 'Lily',
      lastName: 'Lavender',
    },
    {
      id: '100005',
      name: 'Victor Violet',
      firstName: 'Victor',
      lastName: 'Violet',
    },
    {
      id: '100006',
      name: 'Allison Amber',
      firstName: 'Allison',
      lastName: 'Amber',
    },
  ],
  classYears: [2023, 2025],
};

export const school_100003USPU = {
  id: '100003USPU', // IDs here must match IDs in District API
  name: 'North Elm HS',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  hasImportedData: false,
  counselors: [
    {
      id: '100007',
      name: 'Jane Way',
      firstName: 'Jane',
      lastName: 'Way',
    },
    {
      id: '100008',
      name: 'Billy Joe',
      firstName: 'Billy',
      lastName: 'Joe',
    },
  ],
  classYears: [2023, 2024, 2025],
};

export const school_100004USPU = {
  id: '100004USPU', // IDs here must match IDs in District API
  name: 'South Elm HS',
  provisionedStatus: ProvisionStatus.COMPLETED,
  hasStudentReadinessData: true,
  hasImportedData: false,
  counselors: [
    {
      id: '100009',
      name: 'Lilith Lilac',
      firstName: 'Lilith',
      lastName: 'Lilac',
    },
    {
      id: '100010',
      name: 'Val Merkill',
      firstName: 'Val',
      lastName: 'Merkill',
    },
    {
      id: '100011',
      name: 'Stacy Macee',
      firstName: 'Stacy',
      lastName: 'Macee',
    },
  ],
  classYears: [2023, 2025],
};

export const school_100005USPU = {
  id: '100005USPU', // IDs here must match IDs in District API
  name: 'Weeping Willow HS',
  provisionedStatus: ProvisionStatus.IN_PROGRESS,
  hasStudentReadinessData: false,
  hasImportedData: false,
  counselors: [
    {
      id: '1000012',
      name: 'Jack Ryan',
      firstName: 'Jack',
      lastName: 'Ryan',
    },
  ],
  classYears: [2023, 2024],
};

export const school_100006USPU = {
  id: '100006USPU', // IDs here must match IDs in District API
  name: 'Sycamore HS',
  provisionedStatus: ProvisionStatus.NOT_REQUESTED,
  hasStudentReadinessData: false,
  hasImportedData: false,
  counselors: [
    {
      id: '1000013',
      name: 'Enola Holmes',
      firstName: 'Enola Holmes',
      lastName: 'Way',
    },
  ],
  classYears: [2023, 2024],
};

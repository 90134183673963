export enum IndicatorStatus {
  MET = 'Met',
  IN_PROGRESS = 'InProgress',
  NOT_MET = 'NotMet',
  NO_DATA = 'NoData',
}

// This is used to show a user friendly version of the status.
export const INDICATORSTATUSES = [
  { key: IndicatorStatus.MET, value: 'Met' },
  { key: IndicatorStatus.IN_PROGRESS, value: 'In Progress' },
  { key: IndicatorStatus.NOT_MET, value: 'Not Met' },
  { key: IndicatorStatus.NO_DATA, value: 'No Information' },
] as const;

export interface SearchStatus {
  statusCode: number;
  errorMessage: string;
}

const css = `.student-component-requirementInfo-main {
  position: relative;
  margin-top: 10px;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar-inner {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  min-height: 50px;
  height: auto !important;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #c4c9ca;
  border-radius: 4px;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar {
  position: absolute;
  width: 8px;
  left: 0px;
  top: 0%;
  bottom: 0%;
  border-radius: 4px 0px 0px 4px;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar.met {
  background: #3e8d21;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar.inprogress {
  background: #0075db;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar.notmet {
  background: #677274;
}
.student-component-requirementInfo-main .student-component-requirementInfo-bar.nodata {
  background: #c4c9ca;
}
.student-component-requirementInfo-main .student-component-requirementInfo-frame {
  padding: 0px;
  min-height: 27px;
  flex: none;
  order: 0;
  flex-grow: 1;
  width: 12%;
}
.student-component-requirementInfo-main .student-component-requirementInfo-frame .student-component-requirementInfo-title {
  min-height: 27px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
  margin-right: 2px;
}
.student-component-requirementInfo-main .student-component-requirementInfo-status {
  padding: 0px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.student-component-requirementInfo-main .student-component-requirementInfo-status.nodata .label-name {
  font-weight: 400 !important;
  color: #6c7584 !important;
}
.student-component-requirementInfo-main .student-component-requirementInfo-status .label-name {
  height: 21px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  mix-blend-mode: normal;
  flex: none;
  order: 2;
  flex-grow: 0;
}
.student-component-requirementInfo-main .student-component-requirementInfo-status .labeled-icon {
  justify-content: left !important;
  min-width: 108px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50L0NvbXBvbmVudHMvUmVxdWlyZW1lbnRJbmZvIiwic291cmNlcyI6WyJyZXF1aXJlbWVudEluZm8uc2NzcyIsIi4uLy4uL2RlZmluaXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0UsWUNuQ1E7O0FEdUNaO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBLE9DcERNO0VEcUROOztBQUlKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRTtFQUNFO0VBQ0E7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQSxPQ3JGYTtFRHNGYjtFQUNBO0VBQ0E7RUFDQTs7QUFFRjtFQUNFO0VBQ0EifQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};

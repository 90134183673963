import React from 'react';
import { render as DOMRender } from 'react-dom';
import App from './App';

const render = (divId: string) => {
  const widget = divId === 'sri-student-widget';
  const sriMpUrl = document.getElementById(divId)?.getAttribute('x-sri-mp-url') || '';
  const apiUrl = document.getElementById(divId)?.getAttribute('x-sri-api-url') || '';
  const jwt = document.getElementById(divId)?.getAttribute('x-ns-jwt') || '';

  DOMRender(
    <React.StrictMode>
      <App widget={widget} myPlannerUrl={sriMpUrl} apiUrl={apiUrl} jwt={jwt} />
    </React.StrictMode>,
    document.getElementById(divId)
  );
};

(window as unknown as any).LOAD_SRI_STUDENT = (divId: string) => {
  const widget = divId === 'sri-student-widget';
  const sriMpUrl = document.getElementById(divId)?.getAttribute('x-sri-mp-url') || '';
  const apiUrl = document.getElementById(divId)?.getAttribute('x-sri-api-url') || '';
  const jwt = document.getElementById(divId)?.getAttribute('x-ns-jwt') || '';

  DOMRender(
    <React.StrictMode>
      <App widget={widget} myPlannerUrl={sriMpUrl} apiUrl={apiUrl} jwt={jwt} />
    </React.StrictMode>,
    document.getElementById(divId)
  );
};

if (document.getElementById('sri-student-widget')) {
  render('sri-student-widget');
} else if (document.getElementById('sri-student-page')) {
  render('sri-student-page');
}

document.addEventListener('DOMContentLoaded', () => {
  render('sri-student-widget');
  render('sri-student-page');
});

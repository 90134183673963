import { RequirementRuleStatus } from '../../../interfaces/domain';

export const adminStateRules = [
  {
    state: 'OH',
    year: '2023',
    name: 'Graduation Requirements',
    created: '2022-09-02T14:15:12.418Z',
    createdBy: 'Rob Kalmar',
    published: '',
    publishedBy: '',
    status: RequirementRuleStatus.DRAFT,
  },
  {
    state: 'OH',
    year: '2024',
    name: 'Graduation Requirements',
    created: '2022-09-02T14:15:12.418Z',
    createdBy: 'Rob Kalmar',
    published: '2022-09-02T14:15:12.418Z',
    publishedBy: 'Hareesh Kumar',
    status: RequirementRuleStatus.PUBLISHED,
  },
  {
    state: 'TX',
    year: '2023',
    name: 'CCMR',
    created: '2022-09-02T14:15:12.418Z',
    createdBy: 'Rob Kalmar',
    published: '',
    publishedBy: '',
    status: RequirementRuleStatus.DRAFT,
  },
  {
    state: 'TX',
    year: '2024',
    name: 'CCMR',
    created: '2022-09-02T14:15:12.418Z',
    createdBy: 'Rob Kalmar',
    published: '2022-09-02T14:15:12.418Z',
    publishedBy: 'Hareesh Kumar',
    status: RequirementRuleStatus.UNPUBLISHED,
  },
  {
    state: 'TX',
    year: '2025',
    name: 'CCMR',
    created: '2022-09-02T14:15:12.418Z',
    createdBy: 'Rob Kalmar',
    published: '2022-09-02T14:15:12.418Z',
    publishedBy: 'Hareesh Kumar',
    status: RequirementRuleStatus.PUBLISHED,
  },
];

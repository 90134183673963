import React from 'react';
import './icon.scss';
import angle from '../../assets/angle.svg';
import check from '../../assets/check.svg';
import checked from '../../assets/checked.svg';
import info from '../../assets/info.svg';
import InProgress from '../../assets/in-progress.svg';
import Met from '../../assets/met.svg';
import statusActive from '../../assets/met.svg';
import statusInactive from '../../assets/no-data.svg';
import statusInProgress from '../../assets/in-progress.svg';
import NotMet from '../../assets/not-met.svg';
import NoData from '../../assets/no-data.svg';
import help from '../../assets/help.svg';
import search from '../../assets/search.svg';
import triangle from '../../assets/triangle.svg';
import gear from '../../assets/gear.svg';
import sortUp from '../../assets/sort-up.svg';
import sortDown from '../../assets/sort-down.svg';
import caretLeft from '../../assets/caret-left.svg';
import caretRight from '../../assets/caret-right.svg';
import chevronDown from '../../assets/chevron-down.svg';
import chevronRight from '../../assets/chevron-right.svg';
import InProgressActive from '../../assets/in-progress-active.svg';
import MetActive from '../../assets/met-active.svg';
import NotMetActive from '../../assets/not-met-active.svg';
import pencil from '../../assets/pencil.svg';
import success from '../../assets/success.svg';
import warning from '../../assets/warning.svg';
import error from '../../assets/error.svg';
import sriPreview from '../../assets/sri-preview.svg';
import studentPreview from '../../assets/student-preview.svg';
import activationError from '../../assets/activation-error.svg';
import navigationClose from '../../assets/navigation-close.svg';
import cross from '../../assets/cross.svg';
import close from '../../assets/close.svg';
import failedBang from '../../assets/failed-bang.svg';

export type IconColor = 'blue' | 'green' | 'grey';

export interface Props {
  name: string;
  color?: IconColor;
  borderColor?: 'lightblue';
  size?: 16 | 24;
  disabled?: boolean | false;
}

export type IconSvg =
  | 'angle'
  | 'check'
  | 'checked'
  | 'help'
  | 'info'
  | 'InProgress'
  | 'Met'
  | 'NotMet'
  | 'NoData'
  | 'search'
  | 'triangle'
  | 'sort'
  | 'chevronDown'
  | 'chevronRight'
  | 'InProgressActive'
  | 'MetActive'
  | 'NotMetActive'
  | 'pencil'
  | 'success'
  | 'warning'
  | 'error'
  | 'gear'
  | 'sriPreview'
  | 'studentPreview'
  | 'statusActive'
  | 'statusInactive'
  | 'statusInProgress'
  | 'activationError'
  | 'navigationClose'
  | 'cross'
  | 'close'
  | 'failedBang';

export const svgs: Record<string, string> = {
  angle,
  check,
  checked,
  help,
  info,
  InProgress,
  statusActive,
  statusInactive,
  statusInProgress,
  Met,
  NotMet,
  NoData,
  search,
  triangle,
  sortUp,
  sortDown,
  caretLeft,
  caretRight,
  chevronDown,
  chevronRight,
  InProgressActive,
  MetActive,
  NotMetActive,
  pencil,
  success,
  warning,
  error,
  gear,
  sriPreview,
  studentPreview,
  activationError,
  navigationClose,
  cross,
  close,
  failedBang,
};

export const Icon = (props: Props): JSX.Element => {
  const { name, color, borderColor, size = 24, disabled } = props;
  (Icon as React.FC).displayName = 'Icon';
  const borderClass = borderColor ? `sri-icon sri-icon-border` : '';
  const colorClass = color ? `${color}` : '';
  const sizeClass = size === 24 ? 'twenty-four-px' : 'sixteen-px';
  const disabledClass = disabled ? ' disabled-icon' : '';

  const svg: string = (svgs as unknown as [])[name as unknown as number];

  return (
    <React.Fragment>
      <div
        className={`${borderClass} sri-icon-inner ${colorClass} ${sizeClass}${disabledClass}`}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </React.Fragment>
  );
};

import React, { useEffect, useState } from 'react';
import { RequirementInfo } from '../../Components/RequirementInfo';
import { APP_DISPLAY_NAME, STATIC_STUDENT_SCHOOL_MESSAGE, API_ERROR } from '../../constants';
import { Indicator, getIndicators, getStudentDetails } from '../../api';

import './page.scss';
import { LabeledIcon } from '../../../staff/components/LabeledIcon';
import { IndicatorStatus, INDICATORSTATUSES, Student } from '../../../../interfaces/api';
import { IconSvg } from '../../../staff/components/Icon';
import { IndicatorTaskData } from '../../Components/IndicatorTaskData';
import { Loader, LoaderItem } from '../../../staff/components/Loader';
import { EmptyDetails } from '../../Components/EmptyDetails';
import { getStudentMessage } from '../../api/studentMessage';

export interface Props {
  appDisplayName?: string;
  jwt?: string;
  state?: string;
}
export interface Level3Indicator {
  key: string;
  studentName: string;
  studentStatus: IndicatorStatus;
  childrenStudentShortDescription: string;
  childrenStudentLongDescription?: string;
  level3IndicatorCategory?: string;
}
export interface Level2Indicator {
  level2Key: string;
  level2StudentName: string;
  level2StudentStatus: IndicatorStatus;
  level2StudentLabel: string;
  level2IndicatorCategory?: string;
  level2StudentShortDescription: string;
  level2ChildFieldCategory?: string[];
  level3indicatorList: Array<Level3Indicator>;
}

export interface StudentIndicatorDetails {
  topLevelIndicatorKey: string;
  topLevelStudentName: string;
  topLevelStudentStatus: IndicatorStatus;
  topLevelStudentStatusLabel: string;
  studentShortDescription: string;
  topLevelChildFieldCategory?: string[];
  level2indicatorList: Array<Level2Indicator>;
}

export const Page = (props: Props): JSX.Element => {
  (Page as React.FC).displayName = 'Page';
  const { jwt } = props;

  const emptyIndicatorDetails: StudentIndicatorDetails = {
    topLevelIndicatorKey: '',
    topLevelStudentName: '',
    topLevelStudentStatus: IndicatorStatus.NO_DATA,
    topLevelStudentStatusLabel: '',
    studentShortDescription: '',
    level2indicatorList: [],
    topLevelChildFieldCategory: [],
  };
  const [indicatorDetails, setIndicatorDetails] =
    useState<StudentIndicatorDetails>(emptyIndicatorDetails);
  const emptyIndicators: Indicator[] = [];
  const [{ indicators, indicatorsMap }, setIndicators] = useState<{
    indicators: Indicator[];
    indicatorsMap: { [key: string]: Indicator };
  }>({
    indicators: emptyIndicators,
    indicatorsMap: {},
  });

  const [studentDetailsRetrieved, setStudentDetailsRetrieved] = useState<boolean>(false);
  const [schoolProvidedStudentMessage, setSchoolProvidedStudentMessage] = useState<
    string | undefined
  >('');
  const [studentMessage, setStudentMessage] = useState<string | undefined>('');
  const [studentLongDescriptionLeft, setStudentLongDescriptionLeft] = useState<string>('');
  const [studentLongDescriptionRight, setStudentLongDescriptionRight] = useState<string>('');
  const [topLevelRequirementRule, setTopLevelRequirementRule] = useState<string | undefined>('');
  const [metIndicatorCount, setMetIndicatorCount] = useState<number>(0);
  const [loader, setLoader] = useState<LoaderItem>({
    size: 'medium',
    loading: false,
  });
  const [loaderTimedOut, setLoaderTimedOut] = useState<boolean>(false);
  const [invalidData, setInvalidData] = useState<boolean>(false);

  const showLoader = (loaderOps: LoaderItem) => {
    setLoader({
      ...loader,
      ...loaderOps,
    });
  };

  const prepareStudentIndicatorDetails = (studentResults: { [key: string]: IndicatorStatus }) => {
    // Prepare level 1 indicator details
    const topLevelIndicatorDetails: Indicator | undefined = indicators.find(
      (item) => item.depth === 0
    );
    if (topLevelIndicatorDetails) {
      emptyIndicatorDetails.topLevelIndicatorKey = topLevelIndicatorDetails.key;
      emptyIndicatorDetails.topLevelStudentName = topLevelIndicatorDetails.studentName;
      emptyIndicatorDetails.topLevelStudentStatus =
        studentResults[topLevelIndicatorDetails['key']] || IndicatorStatus.NO_DATA;
      emptyIndicatorDetails.studentShortDescription =
        topLevelIndicatorDetails.studentShortDescription;
      emptyIndicatorDetails.topLevelStudentStatusLabel =
        INDICATORSTATUSES.find(
          (indicatorStatus) => indicatorStatus.key === emptyIndicatorDetails.topLevelStudentStatus
        )?.value || '';
      emptyIndicatorDetails.topLevelChildFieldCategory =
        topLevelIndicatorDetails?.childFieldCategories;
    }

    // Prepare level 2 and level 3 indicator list
    const level2IndicatorList: Array<Indicator> = indicators.filter((item) => item.depth === 1);

    if (Array.isArray(level2IndicatorList)) {
      const indicatorStatusList = [];
      for (const indicator of level2IndicatorList) {
        const indicatorChildrenStatusList = [];

        if (indicator.children) {
          for (const childrenIndicator of indicator.children) {
            indicatorChildrenStatusList.push({
              key: childrenIndicator,
              studentName: indicatorsMap[childrenIndicator].studentName,
              studentStatus: studentResults[childrenIndicator],
              childrenStudentShortDescription:
                indicatorsMap[childrenIndicator].studentShortDescription,
              childrenStudentLongDescription:
                indicatorsMap[childrenIndicator]?.studentLongDescription,
              level3IndicatorCategory: indicatorsMap[childrenIndicator]?.category,
            });
          }
        }
        indicatorStatusList.push({
          level2Key: indicator['key'],
          level2StudentName: indicator.studentName,
          level2StudentStatus: studentResults[indicator['key']],
          level2StudentLabel:
            INDICATORSTATUSES.find(
              (indicatorStatus) => indicatorStatus.key === studentResults[indicator['key']]
            )?.value || '',
          level2IndicatorCategory: indicator?.category,
          level2StudentShortDescription: indicator?.studentShortDescription,
          level3indicatorList: indicatorChildrenStatusList,
          level2ChildFieldCategory: indicator?.childFieldCategories,
        });
      }

      // sort by based on status ["Met", "InProgress", "NotMet","NoData"]
      for (const status of INDICATORSTATUSES) {
        emptyIndicatorDetails.level2indicatorList = [
          ...emptyIndicatorDetails.level2indicatorList,
          ...indicatorStatusList
            .filter((indicator) => indicator.level2StudentStatus === status.key)
            .sort((a: Level2Indicator, b: Level2Indicator) =>
              a.level2StudentName.toLowerCase().localeCompare(b.level2StudentName.toLowerCase())
            ),
        ];
      }

      emptyIndicatorDetails.level2indicatorList.map((element, i) => {
        if (element.level3indicatorList.length > 0) {
          let level3indicatorList: Level3Indicator[] = [];
          for (const status of INDICATORSTATUSES) {
            level3indicatorList = [
              ...level3indicatorList,
              ...emptyIndicatorDetails.level2indicatorList[i].level3indicatorList
                .filter((indicator) => indicator.studentStatus === status.key)
                .sort((a: Level3Indicator, b: Level3Indicator) =>
                  a.studentName.toLowerCase().localeCompare(b.studentName.toLowerCase())
                ),
            ];
          }
          emptyIndicatorDetails.level2indicatorList[i].level3indicatorList = level3indicatorList;
        }
      });
    }
    const metIndicatorData = emptyIndicatorDetails.level2indicatorList.filter(
      (indicator) => indicator.level2StudentStatus == 'Met'
    );

    setMetIndicatorCount(metIndicatorData.length);
    setIndicatorDetails(emptyIndicatorDetails);
    setStudentDetailsRetrieved(true);
    showLoader({ loading: false });
  };

  const renderChildrenIndicator = (
    indicatorList: Array<Level3Indicator>,
    studentShortDescription: string,
    childFieldCategories?: string[]
  ) => {
    return (
      <div className="sri-containers-page-indicator-child">
        <div className="sri-containers-page-break-bar"></div>
        <div
          className="sri-containers-page-indicator-shortDescription"
          dangerouslySetInnerHTML={{
            __html:
              indicatorList.length == 1 &&
              indicatorList[0].childrenStudentShortDescription === studentShortDescription
                ? ''
                : studentShortDescription,
          }}
        ></div>

        {childFieldCategories &&
          childFieldCategories.map((subCategory) => (
            <div key={subCategory} className="sri-containers-page-indicator-sub-category">
              <div className="sri-containers-page-indicator-sub-category-name">{subCategory}</div>
              {indicatorList
                .filter((indicator) => indicator.level3IndicatorCategory === subCategory)
                .map((childrenIndicator) => (
                  <div key={childrenIndicator.key}>
                    <RequirementInfo
                      title={childrenIndicator.childrenStudentShortDescription}
                      status={childrenIndicator.studentStatus}
                    />
                  </div>
                ))}
            </div>
          ))}
        {!childFieldCategories &&
          indicatorList.map((childrenIndicator) => (
            <div key={childrenIndicator.key}>
              <RequirementInfo
                title={childrenIndicator.childrenStudentShortDescription}
                status={childrenIndicator.studentStatus}
              />
            </div>
          ))}
      </div>
    );
  };

  const retrieveStudentDetails = async () => {
    const singleStudentDetails = await getStudentDetails(jwt as unknown as string);
    if (
      singleStudentDetails &&
      ((singleStudentDetails as unknown as any).status === API_ERROR ||
        !Object.keys((singleStudentDetails as unknown as any)?.results).length)
    ) {
      setLoader({ loading: false });
      setInvalidData(true);
    } else {
      prepareStudentIndicatorDetails((singleStudentDetails as Student)?.results);
    }
  };

  const retrieveStudentMessage = async () => {
    const studentMessage = await getStudentMessage(jwt as unknown as string);
    if (studentMessage) {
      setStudentMessage(studentMessage.message);
    }
  };

  const getAndParseIndicators = async () => {
    const getData = await getIndicators(jwt as unknown as string);
    if (getData && (getData as unknown as any).status === API_ERROR) {
      setLoader({ loading: false });
      setInvalidData(true);
    } else {
      const indicatorsMap: { [key: string]: Indicator } = getData?.indicatorsMap;
      const indicatorsArray: Indicator[] = Object.values(indicatorsMap);
      const schoolProvidedStudentMessage = getData?.schoolProvidedStudentMessage;
      const topLevelRequirementRule = getData?.topLevelRequirementRule;
      const studentLongDescriptionLeft = getData?.studentLongDescriptionLeft;
      const studentLongDescriptionRight = getData?.studentLongDescriptionRight;
      setIndicators({ indicators: indicatorsArray, indicatorsMap });
      setSchoolProvidedStudentMessage(schoolProvidedStudentMessage);
      setStudentLongDescriptionLeft(studentLongDescriptionLeft);
      setStudentLongDescriptionRight(studentLongDescriptionRight || '');
      setTopLevelRequirementRule(topLevelRequirementRule);
    }
  };

  const handleTimeout = () => {
    setLoaderTimedOut(true);
  };

  useEffect(() => {
    showLoader({ loading: true });
    if (indicators?.length < 1) {
      // only retrieve indicators once
      getAndParseIndicators();
    } else if (indicators[0]) {
      retrieveStudentMessage();
      retrieveStudentDetails();
    }
  }, [indicators]);

  const validDetails =
    studentDetailsRetrieved && indicatorDetails && indicatorDetails.topLevelStudentStatus;
  const invalidDetails = invalidData;

  return (
    <>
      {loader.loading && (
        <div className="sri-containers-page-loader">
          <Loader
            size={loader.size}
            timeout={loader.timeout}
            onTimeout={handleTimeout}
            noToast={true}
          />
        </div>
      )}
      <div className="sri-containers-page-info">
        <div className="sri-containers-page-display-left">
          <span className="sri-containers-page-display-name">{APP_DISPLAY_NAME}</span>
          <br />
          <span
            data-test-id="sri-student-overview-description-left"
            dangerouslySetInnerHTML={{ __html: studentLongDescriptionLeft }}
          ></span>
        </div>
        <div className="sri-containers-page-break-div"></div>
        <div className="sri-containers-page-display-right">
          <span
            className="sri-containers-page-display-instr"
            data-test-id="sri-student-overview-description-right"
            dangerouslySetInnerHTML={{
              __html: studentLongDescriptionRight ? studentLongDescriptionRight : '',
            }}
          ></span>
          <br />
          <strong>Information From My School</strong>
          <br />
          <span
            className="sri-containers-page-display-school-message"
            data-test-id="sri-student-school-message"
            dangerouslySetInnerHTML={{
              __html: studentMessage ? studentMessage : '',
            }}
          ></span>
        </div>
      </div>

      {(loaderTimedOut || invalidDetails) && (
        <div className="sri-containers-page-body">
          <div className="sri-containers-page-header">
            <div className="sri-containers-page-header-title">
              {indicators && indicators[0] ? indicators[0].studentName : 'Readiness Indicators'}
            </div>
          </div>
          <div>
            <EmptyDetails widget={false} />
          </div>
        </div>
      )}

      {validDetails && (
        <div className="sri-containers-page-body">
          <div className="sri-containers-page-header">
            <div className="sri-containers-page-header-title">
              {indicatorDetails.topLevelStudentName}
            </div>
            <div
              className={`sri-containers-page-header-status-info ${indicatorDetails.topLevelStudentStatus?.toLowerCase()}`}
            >
              <LabeledIcon
                iconName={indicatorDetails.topLevelStudentStatus as IconSvg}
                label={indicatorDetails.topLevelStudentStatusLabel}
                labelSide={'right'}
                hideLabelIcon={indicatorDetails.topLevelStudentStatus === IndicatorStatus.NO_DATA}
              />
            </div>
            <div className="sri-containers-page-header-met-info">
              {`${metIndicatorCount} ${
                metIndicatorCount == 0 ? 'indicators ' : 'requirement '
              } met ( ${topLevelRequirementRule})`}
            </div>
          </div>
          <div className="sri-containers-page-all-data">
            <div
              className="sri-containers-page-indicator-description"
              dangerouslySetInnerHTML={{ __html: indicatorDetails.studentShortDescription }}
            ></div>
            <div className="sri-containers-page-indicator-data">
              {indicatorDetails.topLevelChildFieldCategory &&
                indicatorDetails.topLevelChildFieldCategory.map((categoryValue) => (
                  <div key={categoryValue} className="sri-containers-page-category-data">
                    <div className="sri-containers-page-category-name">{categoryValue}</div>
                    {indicatorDetails.level2indicatorList.length > 0 &&
                      indicatorDetails.level2indicatorList
                        .filter((indicator) => indicator.level2IndicatorCategory === categoryValue)
                        .map((secondLevelIndicator, j) => (
                          <IndicatorTaskData
                            key={secondLevelIndicator.level2Key}
                            title={secondLevelIndicator.level2StudentName}
                            taskSide={j % 2 == 0 ? 'left' : 'right'}
                            status={secondLevelIndicator.level2StudentStatus}
                          >
                            <>
                              {secondLevelIndicator.level3indicatorList.length > 0 &&
                                renderChildrenIndicator(
                                  secondLevelIndicator.level3indicatorList,
                                  secondLevelIndicator.level2StudentShortDescription,
                                  secondLevelIndicator.level2ChildFieldCategory
                                )}
                            </>
                          </IndicatorTaskData>
                        ))}
                  </div>
                ))}
              {!indicatorDetails.topLevelChildFieldCategory &&
                indicatorDetails.level2indicatorList.length > 0 &&
                indicatorDetails.level2indicatorList.map((secondLevelIndicator, j) => (
                  <IndicatorTaskData
                    key={secondLevelIndicator.level2Key}
                    title={secondLevelIndicator.level2StudentName}
                    taskSide={j % 2 == 0 ? 'left' : 'right'}
                    status={secondLevelIndicator.level2StudentStatus}
                  >
                    {secondLevelIndicator.level3indicatorList.length > 0 &&
                      renderChildrenIndicator(
                        secondLevelIndicator.level3indicatorList,
                        secondLevelIndicator.level2StudentShortDescription,
                        secondLevelIndicator.level2ChildFieldCategory
                      )}
                  </IndicatorTaskData>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import { IS_STANDALONE_DEV, IS_NAV_STUDENT_DEV, IS_LOCAL_DEV } from '../constants';

export const parseJwt = (jwt: string) => {
  if (!jwt || jwt === '' || IS_STANDALONE_DEV || IS_NAV_STUDENT_DEV || IS_LOCAL_DEV) {
    return {
      highSchoolId: '100001USPU',
      classYear: '2023',
      id: '1',
      state: window.SRI_STUDENT_UI_CONTEXT?.state || 'oh',
    };
  }
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const css = `.sri-widget {
  display: block;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #c4c9ca;
}
.sri-widget .sri-widget-inner {
  display: block;
  padding: 15px;
}
.sri-widget .sri-widget-inner .sri-widget-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c4c9ca;
  padding-bottom: 15px;
}
.sri-widget .sri-widget-inner .sri-widget-text {
  display: block;
  margin-top: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.sri-widget .sri-widget-inner .sri-widget-indicators {
  display: flex;
  justify-content: space-between;
  display: block;
}
.sri-widget .sri-widget-inner .sri-widget-indicators .student-component-requirementInfo-status {
  margin-right: -11px;
}
.sri-widget .sri-widget-inner .sri-widget-main-icon .label-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  mix-blend-mode: normal;
}
.sri-widget .sri-widget-inner .sri-widget-main-icon.nodata .label-name {
  font-weight: 400;
  color: #6c7584;
}
.sri-widget .sri-widget-inner .sri-widget-main-icon .labeled-icon {
  justify-content: left !important;
  min-width: 115px !important;
}

.sri-widget-loader .overlay {
  position: relative;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9mcm9udGVuZC9zdHVkZW50L2NvbnRhaW5lcnMvV2lkZ2V0Iiwic291cmNlcyI6WyJ3aWRnZXQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7O0FBS0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHQTtFQUNFO0VBQ0E7O0FBS047RUFDRTtFQUNBOzs7QUFNSjtFQUNFIn0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};

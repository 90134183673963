/* eslint-disable react/prop-types */
import './App.scss';
import { Page } from './containers/Page';
import { RecursivePage } from './containers/StudentIndicatorDetails';
import React from 'react';

interface AppProps {
  widget?: boolean;
  myPlannerUrl?: string;
  jwt?: string;
  loadPageRecursiveStudentIndicators?: boolean;
}
const AppHelper = (props: AppProps): JSX.Element => {
  (AppHelper as React.FC).displayName = 'SRI App Container';

  const { jwt, loadPageRecursiveStudentIndicators } = props;

  if (loadPageRecursiveStudentIndicators) {
    return <RecursivePage jwt={jwt} />;
  } else {
    return <Page jwt={jwt} />;
  }
};

export default AppHelper;

import { apiClient, parseJwt } from '../utils';
import { GET_STUDENT_STUDENT_DETAILS_ENDPOINT } from '../constants';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Student } from '../../../interfaces/api';

export const getStudentDetails = async (jwt: string): Promise<Student | { status: string }> => {
  const { highSchoolId, classYear, id } = parseJwt(jwt);

  let useMock = '';
  if (jwt === '') {
    useMock = '?useMock=true';
  }

  const baseUrl: string = GET_STUDENT_STUDENT_DETAILS_ENDPOINT;
  const schoolPart: string = highSchoolId ? `/${highSchoolId}` : '';
  const studentPart: string = id ? `/${id}` : '';
  const url = `${baseUrl}${schoolPart}/students/${classYear}${studentPart}${useMock}`;

  // TODO:: use querystring for state only for mocks - remove once real API in place
  const headers: AxiosRequestHeaders = {
    authorization: `Bearer ${jwt}`,
  };

  const config: AxiosRequestConfig = {
    url: url,
    method: 'GET',
    headers: headers,
  };

  const student = await apiClient(config);

  return student;
};

import { StudentResults } from '../../../../interfaces/api/students';
import React from 'react';
import { IndicatorStatus, StateRulesField } from '../../../../interfaces/api';
import { StudentIndicatorHeader } from '../StudentIndicatorHeader/StudentIndicatorHeader';
import './StudentIndicators.scss';

export interface Props {
  studentResults: StudentResults;
  rules: StateRulesField;
  topLevelRequirement?: string;
}

interface Category {
  fields: StateRulesField[];
  categoryName?: string;
}

export const StudentIndicators = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { rules, studentResults, topLevelRequirement } = props;
  const indicatorStatus = studentResults[rules.id] || IndicatorStatus.NO_DATA;
  let children: Category[] = [
    {
      categoryName: '',
      fields: rules.fields?.filter((r) => !r.isScore) || [],
    },
  ];

  if (rules.childFieldCategories?.length) {
    children = rules.childFieldCategories.map((category) => {
      return {
        categoryName: category,
        fields: rules.fields?.filter((c) => c.category === category && !c.isScore) || [],
      };
    });
  }

  const hasChildren = !!children.find((c) => c.fields?.length > 0);

  const renderChildren = (category: Category) => {
    /**
     * Render in 2 columns for depth 0 using markup instead of css
     * to avoid dealing with masonry
     */
    if (rules.depth === 0) {
      return (
        <div className="sri-components-indicatorTaskData-children">
          <div className="sri-components-indicatorTaskData-children-col">
            {category.fields
              .filter((_, i) => i % 2 === 0)
              .map((r) => (
                <StudentIndicators
                  key={`indicator-${r.id}`}
                  studentResults={studentResults}
                  rules={r}
                />
              ))}
          </div>
          <div className="sri-components-indicatorTaskData-children-col">
            {category.fields
              .filter((_, i) => i % 2 === 1)
              .map((r) => (
                <StudentIndicators
                  key={`indicator-${r.id}`}
                  studentResults={studentResults}
                  rules={r}
                />
              ))}
          </div>
        </div>
      );
    }

    return (
      <div className="sri-components-indicatorTaskData-children">
        {category.fields.map((r) => (
          <StudentIndicators key={`indicator-${r.id}`} studentResults={studentResults} rules={r} />
        ))}
      </div>
    );
  };

  return (
    <div
      className={`sri-components-indicatorTaskData-indicator-task depth-${rules.depth} ${
        hasChildren && 'with-children'
      }`}
    >
      {hasChildren && <input type="checkbox" id={`toggle-menu-item-${rules.id}`} />}
      <div
        className={`student-component-requirementInfo-bar ${indicatorStatus?.toLowerCase()}`}
      ></div>
      <label
        className="sri-components-indicatorTaskData-label"
        htmlFor={`toggle-menu-item-${rules.id}`}
      >
        <StudentIndicatorHeader
          studentResults={studentResults}
          topLevelRequirement={topLevelRequirement}
          rules={rules}
        />
      </label>
      {hasChildren && rules.studentShortDescription ? (
        <div
          className="sri-components-indicatorTaskData-description"
          dangerouslySetInnerHTML={{ __html: rules.studentShortDescription || '' }}
        />
      ) : (
        ''
      )}
      {children.map((c) => (
        <div
          className="sri-components-indicatorTaskData-category"
          key={`category-${c.categoryName}`}
        >
          {c.categoryName && (
            <div className="sri-components-indicatorTaskData-category-name">{c.categoryName}</div>
          )}
          {renderChildren(c)}
        </div>
      ))}
    </div>
  );
};

import './labeled-icon.scss';
import React from 'react';

import { Icon, IconSvg } from '../Icon';

export interface Props {
  iconName: IconSvg;
  label?: string;
  labelSide?: 'right' | 'left';
  className?: string;
  hideLabelIcon?: boolean;
}

export const LabeledIcon = (props: React.PropsWithChildren<Props>): JSX.Element => {
  (LabeledIcon as React.FC).displayName = 'LabeledIcon';
  const { iconName, label, labelSide = 'left', className, hideLabelIcon } = props;

  return (
    <div className={`labeled-icon${className ? ` ${className}` : ''}`}>
      {label && labelSide === 'left' && <div className="label-name">{label}</div>}
      {!hideLabelIcon && <Icon name={iconName} />}
      {label && labelSide === 'right' && <div className="label-name">{label}</div>}
    </div>
  );
};

import { StudentResults } from '../../../../interfaces/api/students';
import React from 'react';
import { IndicatorStatus, StateRulesField } from '../../../../interfaces/api';
import { LabeledIcon } from '../../../staff/components/LabeledIcon';
import { IconSvg } from '../../../staff/components/Icon';
import { STATUSES } from '../../constants';
import './StudentIndicatorHeader.scss';
import chevronDown from '../../assets/chevron-down.svg';

export interface Props {
  studentResults: StudentResults;
  rules: StateRulesField;
  topLevelRequirement?: string;
}

export const StudentIndicatorHeader = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const { rules, studentResults, topLevelRequirement } = props;
  const indicatorStatus = studentResults[rules.id] || IndicatorStatus.NO_DATA;
  const children = rules.fields?.filter((r) => !r.isScore) || [];
  const statusLabel = STATUSES.find((status) => status.key === (indicatorStatus as string))?.value;
  const indicatorsMet =
    rules.fields?.filter((r) => studentResults[r.id] === IndicatorStatus.MET).length || 0;
  return (
    <div className="sri-components-indicatorTaskData-header">
      {rules.depth >= 2 && children?.length ? (
        <div className="accordion__arrow" dangerouslySetInnerHTML={{ __html: chevronDown }}></div>
      ) : (
        ''
      )}
      <div className="sri-components-indicatorTaskData-header-title"> {rules.studentName}</div>
      <div className="sri-components-indicatorTaskData-status">
        {indicatorStatus && (
          <LabeledIcon
            iconName={indicatorStatus as IconSvg}
            label={statusLabel}
            labelSide={'right'}
            hideLabelIcon={indicatorStatus === IndicatorStatus.NO_DATA}
          />
        )}

        {rules.depth === 0 ? (
          <div className="sri-components-indicatorTaskData-requirements">
            {indicatorsMet} indicators met ({topLevelRequirement})
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

import { Aggregate, AggregateType } from '../../../interfaces/api';

const resultsMockForOhio = {
  ohio_grad_reqs_prog: 100,
  ohio_course_reqs_prog: 100,
  ohio_course_reqs: 100,
  ohio_competency_prog: 100,
  ohio_readiness_prog: 100,
  ohio_competency_test_scores: 100,
  ohio_competency_college_credit_plus: 100,
  ohio_competency_career_skill: 100,
  ohio_competency_military_readiness: 100,
  ohio_readiness_citizenship: 100,
  ohio_readiness_collegeready: 100,
  ohio_readiness_community_service: 100,
  ohio_readiness_fine_arts: 100,
  ohio_readiness_honors_diploma: 100,
  ohio_readiness_military_enlistment: 100,
  ohio_readiness_ohiomeansjobs: 100,
  ohio_readiness_ind_rec_cred: 100,
  ohio_readiness_science: 100,
  ohio_readiness_biliteracy: 100,
  ohio_readiness_student_engagement: 100,
  ohio_readiness_technology: 100,
};

export const aggregateOh: Aggregate = {
  aggregateType: AggregateType.DISTRICT,
  classYear: 2023,
  results: resultsMockForOhio,
  schools: [
    {
      aggregateType: AggregateType.SCHOOL,
      classYear: 2023,
      results: resultsMockForOhio,
      schoolId: '100001USPU',
    },
  ],
  counselors: [
    {
      aggregateType: AggregateType.COUNSELOR,
      classYear: 2023,
      results: resultsMockForOhio,
      schools: [
        {
          aggregateType: AggregateType.SCHOOL,
          classYear: 2023,
          results: resultsMockForOhio,
          schoolId: '100001USPU',
        },
      ],
      id: '100001',
    },
    {
      aggregateType: AggregateType.COUNSELOR,
      classYear: 2023,
      results: resultsMockForOhio,
      schools: [
        {
          aggregateType: AggregateType.SCHOOL,
          classYear: 2023,
          results: resultsMockForOhio,
          schoolId: '100002USPU',
        },
      ],
      id: '100002',
    },
  ],
};

const resultsMockForTexas = {
  texas_grad_reqs: 100,
  texas_ap_ib: 100,
  texas_ap: 100,
  texas_ib: 100,
  texas_tsi: 100,
  texas_tsi_reading: 100,
  texas_tsi_math: 100,
  texas_dual_credit: 100,
  texas_associates_degree: 100,
  texas_onramps_course: 100,
  texas_industry_certification: 100,
  texas_advanced_degree_plan_and_special_education_services: 100,
  texas_level_one_or_two_certificate: 100,
  texas_iep: 100,
  texas_military: 100,
};

export const aggregateTx: Aggregate = {
  aggregateType: AggregateType.DISTRICT,
  classYear: 2023,
  results: resultsMockForTexas,
  schools: [
    {
      aggregateType: AggregateType.SCHOOL,
      classYear: 2023,
      results: resultsMockForTexas,
      schoolId: '100001USPU',
    },
  ],
  counselors: [
    {
      aggregateType: AggregateType.COUNSELOR,
      classYear: 2023,
      results: resultsMockForTexas,
      schools: [
        {
          aggregateType: AggregateType.SCHOOL,
          classYear: 2023,
          results: resultsMockForTexas,
          schoolId: '100001USPU',
        },
      ],
      id: '100001',
    },
    {
      aggregateType: AggregateType.COUNSELOR,
      classYear: 2023,
      results: resultsMockForTexas,
      schools: [
        {
          aggregateType: AggregateType.SCHOOL,
          classYear: 2023,
          results: resultsMockForTexas,
          schoolId: '100002USPU',
        },
      ],
      id: '100002',
    },
  ],
};

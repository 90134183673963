import { apiClient, parseJwt } from '../utils';
import {
  GET_STUDENT_MESSAGE_DISTRICT_ENDPOINT,
  GET_STUDENT_STUDENT_DETAILS_ENDPOINT,
} from '../constants';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export const getStudentMessage = async (jwt: string) => {
  const { districtId, highSchoolId } = parseJwt(jwt);
  try {
    let useMock = '';
    if (jwt === '') {
      useMock = '?useMock=true';
    }
    const baseUrl: string = districtId
      ? GET_STUDENT_MESSAGE_DISTRICT_ENDPOINT
      : GET_STUDENT_STUDENT_DETAILS_ENDPOINT;
    const schoolPart: string = highSchoolId ? `/${highSchoolId}` : '';
    const schoolOrDistrictPart: string = districtId ? `/${districtId}` : schoolPart;
    const url = `${baseUrl}${schoolOrDistrictPart}/studentmessage${useMock}`;

    const headers: AxiosRequestHeaders = {
      authorization: `Bearer ${jwt}`,
    };

    const config: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      headers: headers,
    };
    const message = await apiClient(config);
    return message;
  } catch (err) {
    return { message: '' };
  }
};

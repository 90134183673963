import { Student, IndicatorStatus } from '../../../interfaces/api';

export const baseStudentOh: Student = {
  id: '123456',
  firstName: 'FirstName',
  lastName: 'LastName',
  counselorId: '100001',
  classYear: 2023,
  dateOfBirth: '2010-02-22',
  ethnicity: 'OT',
  stateStudentId: 'StateStudentId',
  hsStudentId: '',
  results: {
    ohio_grad_reqs_prog: IndicatorStatus.MET,
    ohio_course_reqs_prog: IndicatorStatus.MET,
    ohio_competency_prog: IndicatorStatus.MET,
    ohio_readiness_prog: IndicatorStatus.MET,
    ohio_competency_college_credit_plus: IndicatorStatus.MET,
    ohio_competency_career_skill: IndicatorStatus.IN_PROGRESS,
    ohio_competency_military_readiness: IndicatorStatus.MET,
    ohio_readiness_citizenship: IndicatorStatus.NOT_MET,
    ohio_readiness_collegeready: IndicatorStatus.MET,
    ohio_readiness_community_service: IndicatorStatus.NOT_MET,
    ohio_readiness_fine_arts: IndicatorStatus.MET,
    ohio_readiness_honors_diploma: IndicatorStatus.NO_DATA,
    ohio_readiness_military_enlistment: IndicatorStatus.NOT_MET,
    ohio_readiness_ohiomeansjobs: IndicatorStatus.NOT_MET,
    ohio_readiness_ind_rec_cred: IndicatorStatus.NOT_MET,
    ohio_readiness_science: IndicatorStatus.MET,
    ohio_readiness_biliteracy: IndicatorStatus.MET,
    ohio_readiness_student_engagement: IndicatorStatus.NOT_MET,
    ohio_readiness_technology: IndicatorStatus.MET,
    ohio_course_reqs: IndicatorStatus.MET,
    ohio_competency_test_scores: IndicatorStatus.MET,
  },
  scores: {},
};

export const baseStudentTx: Student = {
  id: '123456',
  firstName: 'FirstName',
  lastName: 'LastName',
  counselorId: '100001',
  classYear: 2023,
  dateOfBirth: '2010-02-22',
  ethnicity: 'OT',
  stateStudentId: 'StateStudentId',
  hsStudentId: '',
  results: {
    texas_grad_reqs: IndicatorStatus.MET,
    texas_ap_ib: IndicatorStatus.MET,
    texas_ap: IndicatorStatus.MET,
    texas_ib: IndicatorStatus.MET,
    texas_tsi: IndicatorStatus.MET,
    texas_tsi_reading: IndicatorStatus.IN_PROGRESS,
    texas_tsi_math: IndicatorStatus.MET,
    texas_dual_credit: IndicatorStatus.NOT_MET,
    texas_associates_degree: IndicatorStatus.MET,
    texas_onramps_course: IndicatorStatus.NOT_MET,
    texas_industry_certification: IndicatorStatus.MET,
    texas_advanced_degree_plan_and_special_education_services: IndicatorStatus.NO_DATA,
    texas_level_one_or_two_certificate: IndicatorStatus.NOT_MET,
    texas_iep: IndicatorStatus.NOT_MET,
    texas_military: IndicatorStatus.NOT_MET,
  },
  scores: {},
};

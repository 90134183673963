export const APP_DISPLAY_NAME = 'Student Readiness Indicators';

// for special tweaks to make local standalone not complain
export const IS_STANDALONE_DEV =
  location.hostname === 'sri-staff.local.naviance.com' ||
  location.hostname === 'sri-student.local.naviance.com';
export const IS_NAV_STUDENT_DEV = location.host === 'localhost:3000';
export const IS_LOCAL_DEV = location.hostname.indexOf('local') > 0;

export const SUCCEED_PROPS = () => {
  return (window as unknown as any).SRI_STAFF_UI_CONTEXT?.props;
};

export const ERROR_MESSAGE_TEXT =
  'Something went wrong. Try again. If the problem persists, please contact support.';
export const SYSTEM_ERROR_TIMEOUT = 15000;
export const TOASTER_DEFAULT_TIMEOUT = 2000;
export const NAVIANCE_SUPPORT_LINK =
  'https://help.powerschool.com/t5/Naviance-Support/ct-p/Naviance_Support';
export const EXPORT_SUCCESS_MESSAGE = 'The .CSV file has been downloaded to your computer.';
export const EXPORT_LINK_EXPIRATION_SECONDS = 7200;

export const GLOBAL_DEPTH = 2; // how to nest the indicators

export enum STATUS {
  ALL = 'all',
  MET = 'Met',
  IN_PROGRESS = 'InProgress',
  NOT_MET = 'NotMet',
  NO_DATA = 'NoData',
}

export const STATUSES = [
  { key: STATUS.MET, value: 'Met' },
  { key: STATUS.IN_PROGRESS, value: 'In Progress' },
  { key: STATUS.NOT_MET, value: 'Not Met' },
  { key: STATUS.NO_DATA, value: 'No Information' },
];

export const ALL_SCHOOLS_ITEM = {
  key: '0',
  value: 'All Schools',
  disabled: false,
};

export const ALL_SCHOOLS_EXPORT_ITEM = {
  key: '0',
  value: 'All Schools and Selected Year',
  disabled: false,
};

export enum EXPORT_CHOICE {
  ALL_SCHOOLS = 'All Schools and Selected Year',
  SELECTED_SCHOOL = 'Selected School and Year',
  SELECTED_COUNSELOR = 'Selected Counselor and Year',
  CURRENT_VIEW = 'Current View Only',
}

export const EXPORT_CHOICES_DISTRICT = [
  { key: '1', value: EXPORT_CHOICE.SELECTED_SCHOOL, disabled: false },
  { key: '2', value: EXPORT_CHOICE.CURRENT_VIEW, disabled: false },
];

export const EXPORT_CHOICES_SCHOOL = [
  { key: '1', value: EXPORT_CHOICE.SELECTED_COUNSELOR, disabled: false },
  { key: '2', value: EXPORT_CHOICE.CURRENT_VIEW, disabled: false },
];

export const EXPORT_BUTTON_STATIC_TEXT = 'Export student data for';

export enum TAB {
  OVERVIEW = 'Overview',
  STUDENT_DETAILS = 'Student Details',
}

export const DISTRICT_VIEW_PERMISSION = 'view_district_reports';
export const DISTRICT_EDIT_PERMISSION = 'edit_sri_dashboard_district';
export const SCHOOL_VIEW_PERMISSION = 'view_school_reports';
export const SCHOOL_EDIT_PERMISSION = 'edit_sri_dashboard_school';
export const EXPORT_DISTRICT_DATA_PERMISSION = 'export_district_data';
export const EXPORT_SCHOOL_DATA_PERMISSION = 'export_school_data';
export const MANAGE_SRI_ACTIVATION_DISTRICT = 'manage_sri_activation_district';
export const MANAGE_SRI_ACTIVATION_SCHOOL = 'manage_sri_activation_school';
export const SCHOOL_IMPORT_PERMISSION = 'import_school_data';
export const DISTRICT_IMPORT_PERMISSION = 'import_district_data';

export const SCHOOL_DATA_IMPORT = '/data-import-new/';
export const DISTRICT_DATA_IMPORT = '/data-import-new/';

import './loader.scss';
import React, { useEffect, useState } from 'react';
import { Toastr } from '../../components/Toastr';
import { ERROR_MESSAGE_TEXT, SYSTEM_ERROR_TIMEOUT } from '../../constants';

type Size = 'small' | 'medium' | 'large';

export interface LoaderItem {
  size?: Size;
  timeout?: number;
  loading?: boolean;
  onTimeout?: () => void;
  noToast?: boolean;
}

export const Loader = (props: LoaderItem): JSX.Element => {
  (Loader as React.FC).displayName = 'Loader';
  const { size, timeout = SYSTEM_ERROR_TIMEOUT, onTimeout, noToast = false } = props;
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showToastr, setShowToastr] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeout) {
        setShowToastr(!noToast && !showToastr);
        setLoading(!isLoading);
        if (onTimeout) {
          onTimeout();
        }
      }
    }, timeout);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className={`loader loader-${size}`}>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
            <div className="loader-bar"></div>
          </div>
        </div>
      )}

      {!noToast && showToastr && <Toastr type="error">{ERROR_MESSAGE_TEXT}</Toastr>}
    </>
  );
};
